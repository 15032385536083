import * as React from 'react';
import axios, {AxiosHeaders, AxiosRequestConfig, Method, RawAxiosRequestHeaders} from 'axios';

type MethodsHeaders = Partial<{
  [Key in Method as Lowercase<Key>]: AxiosHeaders;
} & {common: AxiosHeaders}>;

export async function queryApi<T>(endpoint: string, accountId: string | undefined = undefined,
  method: 'get' | 'post' | 'delete' | 'put' = 'get',
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // Anything more specific would not yield any typing advantage because axios.request() never uses the generic type of data.
  data: Record<string, any> | undefined = undefined): Promise<T> {
  const headers: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders = {
    'accept': 'application/json',
    'content-type': 'application/json',
  };
  if(accountId !== undefined)
    headers['X-Account-Id'] = accountId;

  const requestConfig: AxiosRequestConfig = {method, headers, url: endpoint};
  if(data !== undefined) {
    if(method === 'post' || method === 'put' || method === 'delete')
      requestConfig.data = data;
    else
      requestConfig.params = data;
  }
  const response = await axios.request<T>(requestConfig);
  return response.data;
}

export function getSetter(stateSetter: React.Dispatch<React.SetStateAction<string>>) {
  return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => stateSetter(e.target.value);
}

// TSOA's implementation of assertNever.
export function assertNever(value: never): never {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}
