import React, { useState, useEffect } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import { MdDarkMode } from 'react-icons/md';
import { MdLightMode } from 'react-icons/md';
import { MdBadge } from 'react-icons/md';

import {Outlet} from 'react-router-dom';

function ColorSchemeToggle(props: IconButtonProps) {

  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    setMounted(true);
    setMode('light');
  }, []);

  if(!mounted) {
    return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
  }
  
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      aria-label="toggle light/dark mode"
      {...other}
      onClick={(event) => {
        if(mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
        onClick?.(event);
      }}
    >
      {mode === 'light' ? <MdDarkMode /> : <MdLightMode />}
    </IconButton>
  );
}

export default function Layout() {

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent<Element, Event> | null, newValue: number | string | null) => {
    setTabIndex(parseInt(String(newValue)) ?? 0);
  };

  return <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
              <IconButton variant="soft" color="primary" size="sm">
                <MdBadge />
              </IconButton>
              <Typography level="title-lg">LegalAiKa</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Outlet />
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © LegalAiKa {new Date().getFullYear()} | Version: 0.0.8
            </Typography>
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>;
}