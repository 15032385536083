import * as React from 'react';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import {AnswerItemRes} from '../../data/types';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import { IoIosOpen } from "react-icons/io";

export default function ResultQA(
  props: {
    answer: AnswerItemRes;
  },
) {
  const { answer } = props;

  return <Card>
      <Typography level="title-sm">
        <Typography
          level="title-sm"
          fontFamily="monospace"
          sx={{ opacity: '50%' }}
        >
          Frage:{' '}
        </Typography>
        {answer.query}
      </Typography>
      <Divider/>
      <Typography level="body-sm">
       <Typography
          level="body-sm"
          textColor="var(--joy-palette-success-plainColor)"
          fontFamily="monospace"
          sx={{ opacity: '50%' }}
        >
          Antwort:{' '}
        </Typography>
        {answer.answer}
      </Typography>
      <Divider/>
      <Typography level="body-sm">
       <Typography
          level="body-sm"
          textColor="var(--joy-palette-success-plainColor)"
          fontFamily="monospace"
          sx={{ opacity: '50%' }}
        >
          Dokumente:{' '}
        </Typography>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            my: 2
          })}
        >
        </Divider>
        {answer.usedDocuments.map(d => {
          return <>
            <div>
              <Stack direction="row" alignItems="center" justifyContent="start" gap={1}>
                <span>
                  {d.title + ' (Zeilen: ' + d.lineStart + '...' + d.lineEnd + ')'}
                </span>
                <IconButton component='a' href={d.url} target='_blank'>
                  <IoIosOpen />
                </IconButton>
              </Stack>
              <Tooltip title={'(Zeile Start: ' + d.lineStart + ')...' + d.chunk + '...(Zeile Ende: ' + d.lineEnd + ')'} color="primary" variant="soft" size="sm" sx={{width: '70%', padding:3}}>
                <Box mt={1} mb={2} sx={{padding: '16px', backgroundColor: '#0a6bcb', color: '#f8f8f2', borderRadius: '12px'}}>
                  <code className='language-jsx'>
                    {'...' + d.chunk.substring(0, 1000) + '...'}
                  </code>
                </Box>
              </Tooltip>
            </div>            
          </>;
        })}
      </Typography>
    </Card>;
}