import React, {useState, useCallback} from 'react';
import Card, { CardProps } from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Snackbar from '@mui/joy/Snackbar';
import Link from '@mui/joy/Link';
import LinearProgress from '@mui/joy/LinearProgress';
import Box from '@mui/joy/Box';

import axios from 'axios';
import {useDropzone} from 'react-dropzone';

import {SET_DOCUMENTS} from '../../data/endpoints';
import {UploadedDocumentRes} from '../../data/types';

import { MdFileUpload } from 'react-icons/md';

export default function DropZone(props: CardProps & { icon?: React.ReactElement, disabled: boolean, 
    handler?: (files: UploadedDocumentRes[]) => void }) {
  const { icon, sx, disabled, handler, ...other } = props;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [openSnackbox, setOpenSnackbox] = useState<{severity: 'success' | 'danger', message: string} | null>(null);
  const handleCloseSnackbox = (event: React.SyntheticEvent | Event, reason?: string) => {
    if(reason === 'clickaway') {
      return;
    }
    setOpenSnackbox(null);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => handleUpload(file));
  }, []);

  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
    onDrop, 
    accept: {
      'application/pdf': ['.pdf'],
    }, 
    disabled: disabled || isUploading
  });

  const handleUpload = (newFile: File | null) => {
    if(newFile === null) return;

    /*if(documents.find(d => d.id === newFile.name) !== undefined) {
      setOpenSnackbox({severity: 'error', message: 'Document already uploaded'});
      return;
    }*/

    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', newFile, newFile.name);

    axios.post(SET_DOCUMENTS, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 100));
        setUploadProgress(percentCompleted);
      }
    })
      .then(response => {
        setFile(null);
        if(handler) handler(response.data.files);
        setOpenSnackbox({severity: 'success', message: 'Document(s) successfully uploaded'});
      })
      .catch(error => {
        console.error(error);
        setOpenSnackbox({severity: 'danger', message: 'Document(s) upload error'});
      })
      .finally(() => {
        setIsUploading(false); 
        setUploadProgress(0);     
      });
  };

  return <div {...getRootProps()}>
    <input {...getInputProps()} />
    <Card
      variant="soft"
      {...other}
      sx={[
        {
          borderRadius: 'sm',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          px: 3,
          flexGrow: 1,
          boxShadow: 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {isUploading &&
        <Box sx={{
            'zIndex': 999,
            'opacity': 0.8,
            'position': 'absolute',
            'backgroundColor': 'white',
            'width': '100%',
            'height': '100%',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
          }}>
          <LinearProgress
            determinate
            variant="outlined"
            color="primary"
            size="sm"
            thickness={24}
            value={uploadProgress}
            sx={{
              '--LinearProgress-radius': '20px',
              '--LinearProgress-thickness': '24px',
              'width': '50%',
              'border': 0,
            }}
          >
            <Typography
              level="body-xs"
              fontWeight="xl"
              textColor="white"
              sx={{ mixBlendMode: 'difference' }}
            >
              Uploading... {`${Math.round(uploadProgress)}%`}
            </Typography>
          </LinearProgress>
        </Box>
      }
      <AspectRatio
        ratio="1"
        variant="solid"
        color="primary"
        sx={{
          minWidth: 20,
          borderRadius: '50%',
          '--Icon-fontSize': '10px',
        }}
      >
        <div>{icon ?? <MdFileUpload />}</div>
      </AspectRatio>
      <Typography level="body-sm" textAlign="center">
        {
          isDragActive ?
            <>Drop Dokument(e) hier ...</> :
            <><Link component="button" overlay>Klicken zum hochladen</Link> oder Drag&Drop</>
        }
      </Typography>
      <Typography level="body-xs" textAlign="center">Hinweis: Es erfolgt keine Speicherung im Dokumentenarchiv</Typography>
    </Card>
    <Snackbar
      autoHideDuration={5000}
      open={!!openSnackbox}
      color={openSnackbox?.severity ?? 'success'}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={(event, reason) => {
        if(reason === 'clickaway') {
          return;
        }
        setOpenSnackbox(null);
      }}
    >
      {openSnackbox?.message}
    </Snackbar>
  </div>;
}