import React, {useState, useEffect} from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Autocomplete from '@mui/joy/Autocomplete';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';

import { IoClose } from 'react-icons/io5';
import { FaFileWord } from "react-icons/fa";
import { HiClipboardCopy } from "react-icons/hi";
import { FaCheckCircle } from "react-icons/fa";
import { MdSmsFailed } from "react-icons/md";

import ResultQA from './ResultQA';
import QuestionItem from './QuestionItem';
import DropZone from '../common/DropZone';
import {UploadedDocumentRes} from '../../data/types';

import { v4 as uuidv4 } from 'uuid';

import {AnswerRes, AnswerItemRes, ExtractQuestion} from '../../data/types';
import {GET_ANSWERS} from '../../data/endpoints';
import {queryApi} from '../../utils/misc';

import { Packer } from "docx";
import {DocumentCreator} from '../common/DocumentCreator';
import { saveAs } from "file-saver";

import {
  useRecoilState,
} from 'recoil';

import {documentsAtom} from '../../data/atoms';

interface TemplateType {
  title: string, 
  questions: string[]
}

export default function Extract({isFactVerification}: {
  isFactVerification: boolean;
}) {

  const [documents, setDocuments] = useRecoilState<UploadedDocumentRes[]>(documentsAtom);

  const [documentsSelected, setDocumentsSelected] = useState<UploadedDocumentRes[]>([]);

  const [questionTemplates, setQuestionTemplates] = useState<TemplateType[]>([{title: 'Standard-Vorlage', questions: ['Frage 1', 'Frage 2']}]);
  const [templateName, setTemplateName] = useState<string>('');

  const [questions, setQuestions] = useState<ExtractQuestion[]>([{id: uuidv4(), text: ''}]);

  const [loading, setLoading] = useState<boolean>(false);
  const [matchFilter, setMatchFilter] = useState(['ok', 'nok']);
  const [answers, setAnswers] = useState<AnswerItemRes[]>([]);
  const [filteredAnswers, setFilteredAnswers] = useState<AnswerItemRes[]>([]);
  const [getAnswerLocked, setGetAnswerLocked] = useState<boolean>(false);

  const fileHandler = (uploadedDocuments: UploadedDocumentRes[]) => {
    setDocuments((currentDocuments) => {
      return Object.values(
        [...currentDocuments, ...uploadedDocuments]
          .reduce((acc: any, obj: UploadedDocumentRes) => ({ ...acc, [obj.fileName]: obj }), {})
      ) as UploadedDocumentRes[];
    });
    setDocumentsSelected((currentDocumentsSelected) => {
      return Object.values(
        [...currentDocumentsSelected, ...uploadedDocuments]
          .reduce((acc: any, obj: UploadedDocumentRes) => ({ ...acc, [obj.fileName]: obj }), {})
      ) as UploadedDocumentRes[];
    });
  };

  const handleTemplateSelect = (data: TemplateType | null) => {
    if(data === null) {
      setTemplateName('');
      return;
    }
    setQuestions([...data.questions.map(question => { 
      return {
        'id': uuidv4(), 
        'text': question
      };
    })]);
    setTemplateName(data.title);
  };

  const handleTemplateSave = () => {
    if(templateName === '') return;
    const templateQuestions = questions.filter(question => question.text.trim().length > 0).map(question => question.text.trim());
    const existingTemplate = questionTemplates.find(questionTemplate => questionTemplate.title === templateName) ?? null;
    const currentTemplate = (() => {
      if(existingTemplate !== null) {
        existingTemplate.questions = templateQuestions;
        setQuestionTemplates([...questionTemplates]);
        return existingTemplate;
      } else {
        const newTemplate = {
          title: templateName, 
          questions: templateQuestions
        };
        setQuestionTemplates([...questionTemplates, newTemplate]);
        return newTemplate;
      }
    })();
    setQuestions([...currentTemplate.questions.map(question => { 
      return {
        'id': uuidv4(), 
        'text': question
      };
    })]);
  };

  const handleAdd = () => {
    setQuestions([...questions, {id: uuidv4(), text: ''}]);
    setGetAnswerLocked(false);
  };

  const handleChange = (id: string, text: string) => {
    const question = questions.find(q => q.id === id) ?? null;
    if(question === null) return;
    question.text = text;
    setQuestions([...questions]);
    setGetAnswerLocked(false);
  };

  const handleDelete = (id: string) => {
    if(questions.length < 2) return;
    setQuestions([...questions.filter(q => q.id !== id)]);
    setGetAnswerLocked(false);
  };

  async function getAnswers() {
    if(questions.length === 0) return;

    setAnswers([]);
    setLoading(true);
    const url = GET_ANSWERS
      .replace('{questions}', questions.map(q => q.text.trim()).join('|||'))
      .replace('{documentIds}', documentsSelected.map(q => q.fileName.trim()).join('|||'))
      .replace('{isFactVerification}', String(isFactVerification));

    const result = await queryApi<AnswerRes>(url);
    setLoading(false);

    setGetAnswerLocked(true);
    setAnswers(result.answers);
  }

  useEffect(() => {
    setFilteredAnswers(answers.filter(
      answer => answer.matched ? matchFilter.includes('ok') : matchFilter.includes('nok')));
  }, [matchFilter, answers]);

  const copyResultsToClipboard = () => {
    navigator.clipboard.writeText(answers.map(answer => {
      return "Frage: " + answer.query + " Antwort: " + answer.answer + 
        " Anzahl referenzierter Dokumente: " + answer.usedDocuments.length;
    }).join(' | '));
  };

  const exportAsdocument = () => {
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create(answers);
    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, 'example.docx');
      console.log('Document created successfully');
    });
  };

  return <>
    <Stack gap={2}>
      <DropZone disabled={loading} handler={fileHandler}/>
    </Stack>
    {documents.length > 0 &&
      <Stack gap={2}>
        <FormControl id="multiple-limit-tags" sx={{ width: '100%' }}>
          <Autocomplete
            multiple
            options={documents}
            value={documentsSelected}
            onChange={(event, newValue) => {
              setDocumentsSelected(newValue);
            }}
            getOptionLabel={(option) => option.fileName}
            isOptionEqualToValue={(option, value) => option.fileName === value.fileName}
            sx={{ width: '100%', '--Input-decoratorChildHeight': '45px' }}
            disabled={loading}
            disableClearable
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => (
                <Chip
                  variant="solid"
                  color="primary"
                  endDecorator={<IoClose fontSize="sm" />}
                  {...getTagProps({ index })}
                >
                  {item.fileName}
                </Chip>
              ))
            }
          />
        </FormControl>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            mt: 3
          })}
        >
          Extraktions-Vorlagen öffnen oder Fragen eingeben
        </Divider>
        <Stack direction="row" alignItems="start" justifyContent="end" gap={2}>
          <FormControl id="multiple-limit-tags">
            <Autocomplete
              sx={{ '--Input-decoratorChildHeight': '45px' }}
              placeholder="Vorlage auswählen"
              options={questionTemplates}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              disabled={loading}
              onChange={(_, newValue) => {
                handleTemplateSelect(newValue);
              }}
            />
          </FormControl>
          <FormControl>
            <Input
              sx={{ '--Input-decoratorChildHeight': '45px' }}
              placeholder="Vorlagen-Name"
              type="text"
              required
              disabled={loading || templateName === ''}
              value={templateName}
              onChange={(event) =>
                setTemplateName(event.target.value)
              }
              endDecorator={
                <Button
                  variant="solid"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  onClick={handleTemplateSave}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Speichern
                </Button>
              }
            />
          </FormControl>
        </Stack>
        {questions.map((question: ExtractQuestion, idx: number) => {
          return <QuestionItem
            key={question.id}
            questionId={question.id}
            defaultText={question.text}
            onChange={handleChange}
            onDelete={handleDelete}
            onAdd={handleAdd}
            hideDelete={questions.length < 2}
            hideAdd={questions.length-1 !== idx}
            disabled={loading}
            disableDelete={questions.length < 2}
            disableAdd={questions.length-1 !== idx || questions.find(q => q.text === '') !== undefined}
          />;
        })}
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            mt: 3
          })}
        />
        <Button disabled={questions.length === 0 || 
            questions.find(q => q.text === '') !== undefined ||
            loading || 
            getAnswerLocked} loading={loading} 
          loadingPosition="end" onClick={getAnswers}>Jetzt Antwort(en) extrahieren</Button>
      </Stack>
    }
    {answers.length > 0 &&
      <>
        <Stack gap={2}>
          <Divider
            sx={(theme) => ({
              [theme.getColorSchemeSelector('light')]: {
                color: { xs: '#FFF', md: 'text.tertiary' },
                '--Divider-lineColor': {
                  xs: '#FFF',
                  md: 'var(--joy-palette-divider)',
                },
              },
              mt: 3
            })}
          >
            Antworten auf Ihre Fragen
          </Divider>
          {isFactVerification && 
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
              <Typography style={{color: answers.filter(a => !a.matched).length > 0 ? 'red' : 'green'}}>
                {answers.filter(a => !a.matched).length + ' / ' + answers.length + ' Eckdaten haben einen Fehler'}
              </Typography>
              <ToggleButtonGroup
                value={matchFilter}
                onChange={(event, newValue) => {
                  setMatchFilter(newValue);
                }}
              >
                <IconButton value="ok">
                  <FaCheckCircle />
                </IconButton>
                <IconButton  value="nok">
                  <MdSmsFailed />
                </IconButton>
              </ToggleButtonGroup>
            </Stack>
          }
          <Divider
            sx={(theme) => ({
              [theme.getColorSchemeSelector('light')]: {
                color: { xs: '#FFF', md: 'text.tertiary' },
                '--Divider-lineColor': {
                  xs: '#FFF',
                  md: 'var(--joy-palette-divider)',
                },
              },
              mt: 3
            })}
          />
          {filteredAnswers.map(answer => 
            <ResultQA key={answer.query} answer={answer} />
          )}
        </Stack>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            my: 3
          })}
        />
        <Stack direction="row" alignItems="start" justifyContent="start" gap={2}>
          <Button variant="outlined" color="neutral" sx={{ '--Input-decoratorChildHeight': '45px' }} 
            startDecorator={<HiClipboardCopy />} 
            disabled={loading || answers.length === 0}
            onClick={copyResultsToClipboard}><Typography fontSize="xs" style={{whiteSpace: 'nowrap'}}>Copy to clipboard</Typography></Button>
          <Button variant="outlined" color="neutral" sx={{ '--Input-decoratorChildHeight': '45px' }}
            startDecorator={<FaFileWord />} 
            disabled={loading || answers.length === 0}
            onClick={exportAsdocument}><Typography fontSize="xs" style={{whiteSpace: 'nowrap'}}>Export as Table</Typography></Button>
        </Stack>    
      </>
    }
  </>;
}