import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { formLabelClasses } from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';

import Extract from './extract/Extract';
import Question from './question/Question';

export default function InfoExtractHome() {

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent<Element, Event> | null, newValue: number | string | null) => {
    setTabIndex(parseInt(String(newValue)) ?? 0);
  };

  return <Box
      component="main"
      sx={{
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
        [`& .${formLabelClasses.asterisk}`]: {
          visibility: 'hidden',
        },
      }}
    >
      <Stack gap={2}>
        <Tabs aria-label="tabs" size="sm" value={tabIndex} onChange={handleChange} sx={{ bgcolor: 'transparent' }}>
          <TabList
            disableUnderline
            sx={{
              p: 0.5,
              mb: 2,
              gap: 0.5,
              borderRadius: 'xl',
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.level1',
              },
            }}
          >
            <Tab disableIndicator>Extraktion</Tab>
            <Tab disableIndicator>Verifikation</Tab>
            <Tab disableIndicator>Suche</Tab>
          </TabList>
          <TabPanel value={0}>
            <Extract isFactVerification={false}/>
          </TabPanel>
          <TabPanel value={1}>
            <Extract isFactVerification={true}/>
          </TabPanel>
          <TabPanel value={2}>
            <Question/>
          </TabPanel>
        </Tabs>
      </Stack>
    </Box>;
}