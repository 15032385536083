const ENV_CONFIG: {API_URL?: string} = (window as Window & typeof globalThis & {'_env_': Record<string, string>})._env_ ?? {};

const SERVER_ENDPOINT: string = ENV_CONFIG.API_URL ?? 'http://localhost:8080/api';

export const SET_DOCUMENTS: string = SERVER_ENDPOINT + '/upload';
export const GET_ANSWERS: string = SERVER_ENDPOINT + '/answers?questions={questions}&documentIds={documentIds}&isFactVerification={isFactVerification}';

export const DEFAULT_HEADER = {
  'accept': 'application/json',
  'content-type': 'application/json',
};
