import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
} from "docx";

import {AnswerItemRes} from '../../data/types';

export class DocumentCreator {
  public create(answers: AnswerItemRes[]): Document {
    const document = new Document({
      sections: [
        {
          children: [
            ...answers.map(answer => {
                const arr: Paragraph[] = [];
                arr.push(
                  new Paragraph({
                    text: 'Frage: ' + answer.query,
                    heading: HeadingLevel.HEADING_1,
                  })
                );
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Antwort: ' + answer.answer,
                        bold: false
                      })
                    ]
                  })
                );
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Anzahl referenzierter Dokumente ' + answer.usedDocuments.length,
                        italics: true
                      })
                    ]
                  })
                );
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: '',
                      })
                    ]
                  })
                );
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
          ]
        }
      ]
    });

    return document;
  }
}