import React, {useState, useEffect} from 'react';
import Textarea from '@mui/joy/Textarea';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';

import { RiDeleteBinFill } from 'react-icons/ri';
import { MdAddCircle } from 'react-icons/md';

export default function QuestionItem({questionId, onChange, onDelete, onAdd, hideAdd, hideDelete, disabled, disableDelete, disableAdd, defaultText}: {
  questionId: string;
  onChange: (questionId: string, text: string) => void;
  onDelete: (questionId: string) => void;
  onAdd: () => void;
  hideAdd: boolean;
  hideDelete: boolean;
  disabled: boolean;
  disableDelete: boolean;
  disableAdd: boolean;
  defaultText?: string;
}) {

  const [text, setText] = useState<string>(defaultText ?? '');
  
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setText(event.target.value);
  };

  const handleAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onAdd();
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onDelete(questionId);
  };

  useEffect(() => {
    onChange(questionId, text);
  }, [text]);

  return <Stack direction={'row'} sx={{ width: '100%' }} gap={0.5}>
    <Stack direction={'column'} justifyContent={'space-between'} gap={0.3}>
      {!hideDelete && <IconButton variant="soft" color={'primary'} style={{height: '16px', width: '16px'}} disabled={disableDelete || disabled} onClick={handleDelete}>
        <RiDeleteBinFill />
      </IconButton>}
      {!hideAdd && <IconButton variant="soft" color={'primary'} style={{height: '16px', width: '16px'}} disabled={disableAdd || disabled} onClick={handleAdd}>
        <MdAddCircle />
      </IconButton>}
    </Stack>
    <Textarea
      size="sm"
      minRows={3}
      value={text}
      onChange={handleChange}
      placeholder="Deine Frage..."
      sx={{ width: '100%' }}
      disabled={disabled}
    />
  </Stack>;
}