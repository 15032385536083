import {
  atom,
} from 'recoil';

import {UploadedDocumentRes} from './types';

export const documentsAtom = atom<UploadedDocumentRes[]>({
  key: 'documents',
  default: [],
});
