import React, {useState} from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Textarea from '@mui/joy/Textarea';
import Card from '@mui/joy/Card';
import Autocomplete from '@mui/joy/Autocomplete';
import FormControl from '@mui/joy/FormControl';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/joy/IconButton';

import { IoClose } from 'react-icons/io5';
import { FaFileWord } from "react-icons/fa";
import { HiClipboardCopy } from "react-icons/hi";
import { IoIosOpen } from "react-icons/io";

import DropZone from '../common/DropZone';
import {UploadedDocumentRes} from '../../data/types';

import {AnswerRes, AnswerItemRes} from '../../data/types';
import {GET_ANSWERS} from '../../data/endpoints';
import {queryApi} from '../../utils/misc';

import { Packer } from "docx";
import {DocumentCreator} from '../common/DocumentCreator';
import { saveAs } from "file-saver";

import {
  useRecoilState,
} from 'recoil';

import {documentsAtom} from '../../data/atoms';

export default function Question() {

  const [documents, setDocuments] = useRecoilState<UploadedDocumentRes[]>(documentsAtom);

  const [documentsSelected, setDocumentsSelected] = useState<UploadedDocumentRes[]>([]);

  const [question, setQuestion] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [answer, setAnswer] = useState<AnswerItemRes | null>(null);
  const [getAnswerLocked, setGetAnswerLocked] = useState<boolean>(false);

  const fileHandler = (uploadedDocuments: UploadedDocumentRes[]) => {
    setDocuments((currentDocuments) => {
      return Object.values(
        [...currentDocuments, ...uploadedDocuments]
          .reduce((acc: any, obj: UploadedDocumentRes) => ({ ...acc, [obj.fileName]: obj }), {})
      ) as UploadedDocumentRes[];
    });
    setDocumentsSelected((currentDocumentsSelected) => {
      return Object.values(
        [...currentDocumentsSelected, ...uploadedDocuments]
          .reduce((acc: any, obj: UploadedDocumentRes) => ({ ...acc, [obj.fileName]: obj }), {})
      ) as UploadedDocumentRes[];
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGetAnswerLocked(false);
    setQuestion(event.target.value);
  };

  async function getAnswers() {
    if(question === '') return;

    setAnswer(null);
    setLoading(true);
    const url = GET_ANSWERS
      .replace('{questions}', question.trim())
      .replace('{documentIds}', documentsSelected.map(q => q.fileName.trim()).join(','))
      .replace('{isFactVerification}', String(false));

    const result = await queryApi<AnswerRes>(url);
    setLoading(false);

    setGetAnswerLocked(true);
    setAnswer(result.answers.length > 0 ? result.answers[0] : null);
  }

  const copyResultsToClipboard = () => {
    if(answer === null) return;
    navigator.clipboard.writeText("Frage: " + answer.query + " Antwort: " + answer.answer + 
        " Anzahl referenzierter Dokumente: " + answer.usedDocuments.length);
  };

  const exportAsdocument = () => {
    if(answer === null) return;
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create([answer]);
    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, 'example.docx');
      console.log('Document created successfully');
    });
  };

  return <>
    <Stack gap={2}>
      <DropZone disabled={loading} handler={fileHandler}/>
    </Stack>
    {documents.length > 0 &&
      <Stack gap={2}>
        <FormControl id="multiple-limit-tags" sx={{ width: '100%' }}>
          <Autocomplete
            multiple
            options={documents}
            value={documentsSelected}
            onChange={(event, newValue) => {
              setDocumentsSelected(newValue);
            }}
            getOptionLabel={(option) => option.fileName}
            isOptionEqualToValue={(option, value) => option.fileName === value.fileName}
            sx={{ width: '100%', '--Input-decoratorChildHeight': '45px' }}
            disabled={loading}
            disableClearable
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => (
                <Chip
                  variant="solid"
                  color="primary"
                  endDecorator={<IoClose fontSize="sm" />}
                  {...getTagProps({ index })}
                >
                  {item.fileName}
                </Chip>
              ))
            }
          />
        </FormControl>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            mt: 3
          })}
        >
          Frage eingeben
        </Divider>
        <Textarea
          size="sm"
          minRows={3}
          placeholder="Deine Frage..."
          value={question}
          onChange={handleChange}
          disabled={loading}
        />
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            mt: 3
          })}
        />
        <Button disabled={question === '' || loading || getAnswerLocked} loading={loading} 
          loadingPosition="end" onClick={getAnswers}>Jetzt Antwort erhalten</Button>
      </Stack>
    }
    {answer &&
      <>
        <Stack gap={2}>
          <Divider
            sx={(theme) => ({
              [theme.getColorSchemeSelector('light')]: {
                color: { xs: '#FFF', md: 'text.tertiary' },
                '--Divider-lineColor': {
                  xs: '#FFF',
                  md: 'var(--joy-palette-divider)',
                },
              },
              mt: 3
            })}
          >
            Antwort auf Ihre Frage
          </Divider>
          <Card>
            <Typography level="body-sm">
             <Typography
                level="body-sm"
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
              >
                Antwort:{' '}
              </Typography>
              {answer.answer}
            </Typography>
            <Divider/>
            <Typography level="body-sm">
             <Typography
                level="body-sm"
                textColor="var(--joy-palette-success-plainColor)"
                fontFamily="monospace"
                sx={{ opacity: '50%' }}
              >
                Dokumente:{' '}
              </Typography>
              
              <Divider
                sx={(theme) => ({
                  [theme.getColorSchemeSelector('light')]: {
                    color: { xs: '#FFF', md: 'text.tertiary' },
                    '--Divider-lineColor': {
                      xs: '#FFF',
                      md: 'var(--joy-palette-divider)',
                    },
                  },
                  my: 2
                })}
              >
              </Divider>
              {answer.usedDocuments.map(d => {
                return <div key={d.id}>
                    <Stack direction="row" alignItems="center" justifyContent="start" gap={1}>
                      <span>
                        {d.title + ' (Zeilen: ' + d.lineStart + '...' + d.lineEnd + ')'}
                      </span>
                      <IconButton component='a' href={d.url} target='_blank'>
                        <IoIosOpen />
                      </IconButton>
                    </Stack>
                    <Tooltip title={'(Zeile Start: ' + d.lineStart + ')...' + d.chunk + '...(Zeile Ende: ' + d.lineEnd + ')'} color="primary" variant="soft" size="sm" sx={{width: '70%', padding:3}}>
                      <Box mt={1} mb={2} sx={{padding: '16px', backgroundColor: '#0a6bcb', color: '#f8f8f2', borderRadius: '12px'}}>
                        <code className='language-jsx'>
                          {'...' + d.chunk.substring(0, 1000) + '...'}
                        </code>
                      </Box>
                    </Tooltip>
                  </div>;
              })}
            </Typography>
          </Card>
        </Stack>
        <Divider
          sx={(theme) => ({
            [theme.getColorSchemeSelector('light')]: {
              color: { xs: '#FFF', md: 'text.tertiary' },
              '--Divider-lineColor': {
                xs: '#FFF',
                md: 'var(--joy-palette-divider)',
              },
            },
            my: 3
          })}
        />
        <Stack direction="row" alignItems="start" justifyContent="start" gap={2}>
          <Button variant="outlined" color="neutral" sx={{ '--Input-decoratorChildHeight': '45px' }} 
            startDecorator={<HiClipboardCopy />} 
            disabled={loading || answer === null}
            onClick={copyResultsToClipboard}><Typography fontSize="xs" style={{whiteSpace: 'nowrap'}}>Copy to clipboard</Typography></Button>
          <Button variant="outlined" color="neutral" sx={{ '--Input-decoratorChildHeight': '45px' }}
            startDecorator={<FaFileWord />} 
            disabled={loading || answer === null}
            onClick={exportAsdocument}><Typography fontSize="xs" style={{whiteSpace: 'nowrap'}}>Export as Table</Typography></Button>
        </Stack>  
      </>
    }
  </>;
}