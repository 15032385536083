import Layout from './views/Layout';
import NotFound from './views/NotFound';
import Documents from './views/documents/Documents';
import InfoExtractHome from './views/InfoExtractHome';
import {Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
//import {ReactQueryDevtools} from 'react-query/devtools';

function App() {

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<InfoExtractHome />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/documents" element={<Layout />}>
          <Route index element={<Documents />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
}

export default App;
